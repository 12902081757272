import React from "react"
import { SRLWrapper } from "simple-react-lightbox"
import Banner from "../../components/Banner"
import Layout from "../../components/core/Layout"
import SEO from "../../components/core/SEO"
import Images from "../../components/Images"

const PandemicsInCeramicsPage: React.FC = () => (
  <Layout activePage="projects">
    <SEO title="Pandemics in Ceramics" />
    <Banner>
      <p>
        <b>This project is now completed.</b>
        <br />
        Thanks to Professor Carenza Lewis and the University of Lincoln for
        making this project possible.
        <br />
      </p>
    </Banner>
    <h1 className="page-title">Pandemics in Ceramics</h1>
    <div className="community-clay__container">
      <div></div>
      <SRLWrapper>
        <Images image="scotthouselarge" />
      </SRLWrapper>
    </div>
  </Layout>
)

export default PandemicsInCeramicsPage
